/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/

angular.module('peers',
    ['restangular', 'datatables', 'datatables.bootstrap', 'ui.bootstrap', 'ui.router', 'nvd3', 'ngSanitize','cc.autorefresh', 'ng-countryflags']);

angular.module('peers').constant('peerConfig', {
  'apiUrl': window.getEnvConfig("PEER_EXPLORER_API_URL") || 'http://185.103.75.217:8888/',
  //Replace IP with your own VPS
  'peerEndPoint': 'api/nodes'
});

angular.module('peers').constant('PEER_CONSTANTS', {
    'REFRESH_INTERVAL_MILLI_SECONDS': window.getEnvConfig("CONSTANTS_REFRESH_INTERVAL") || 60000,
    'VERSION': window.getEnvConfig("RELEASE_VERSION") || '0.4.1',
    'NETWORK_ENVIRONMENT': window.getEnvConfig("NETWORK_ENVIRONMENT"),
});

angular.module('peers').config(['RestangularProvider', 'peerConfig', '$stateProvider', '$urlRouterProvider',
    function (RestangularProvider, peerConfig, $stateProvider, $urlRouterProvider) {

        RestangularProvider.setBaseUrl(peerConfig.apiUrl);

        $stateProvider.state('peerExplorer.peers', {
            url: '^/peers',
            templateUrl: './peers/peers.html',
            controller: 'PeersCtrl'

        });

    }]);

angular.module('peers').filter('searchTerm', ['$sce', function ($sce) {
    return function (val) {
        if (val) {
            return '<a href="" ng-controller="SearchCtrl" ng-click="search(\'' + val +
                '\')">' + val + '</a>';
        } else {
            return '';
        }
    };
}]);

angular.module('peers').directive('compile', ['$compile', function ($compile) {
    return function (scope, element, attrs) {
        scope.$watch(
            function (scope) {
                // watch the 'compile' expression for changes
                return scope.$eval(attrs.compile);
            },
            function (value) {
                // when the 'compile' expression changes
                // assign it into the current DOM
                element.html(value);

                // compile the new DOM and link it to the current
                // scope.
                // NOTE: we only compile .childNodes so that
                // we don't get into infinite loop compiling ourselves
                $compile(element.contents())(scope);
            }
        );
    };
}]);

/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/

angular.module('peers')
    .service('PeerService', ['Restangular', 'peerConfig', '$q', function (Restangular, peerConfig, $q) {
        this.getPeers = function (page, results) {
                var params = {
                    'page': page,
                    'results': results,
                    'filter': 'numberOfActivePeers',
                    'order': 'desc'
                };

                return Restangular.all(peerConfig.peerEndPoint).customGET('', params);
            };

            this.getStats = function () {
                return Restangular.all('api').one('getStats').customGET('', '');
            };

    }]);

/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/

angular.module('peers').controller('PeersCtrl',
    ['$scope', 'PeerService', 'DTOptionsBuilder', 'DTColumnBuilder', '$interval', '$uibModal', '$compile',
        function ($scope, PeerService, DTOptionsBuilder, DTColumnBuilder, $interval, $uibModal, $compile) {

            $scope.chartOptions = {
                chart: {
                    type: 'discreteBarChart',
                    height: 20,
                    margin: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                    x: function (d) {
                        return d.label;
                    },
                    y: function (d) {
                        return d.value;
                    },
                    showValues: false,
                    duration: 500,
                    xAxis: {
                        axisLabel: '',
                        axisLabelDistance: 0,
                        ticks: 0
                    },
                    yAxis: {
                        axisLabel: '',
                        axisLabelDistance: 0,
                        ticks: 0
                    },

                    color: function () {
                        return '#666';
                    },


                },

            };

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withPaginationType('numbers')
                .withDOM('frtip')
                .withDataProp('data')
                .withOption('responsive', true)
                .withOption('paging', true)
                .withOption('ordering', false)
                .withOption('info', false)
                .withOption('serverSide', true)
                .withDataProp('peers')
                .withOption('processing', true)
                .withOption('bFilter', false)
                .withOption('stateSave', true)
                .withOption('fnRowCallback',
                    function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                        $compile(nRow)($scope);
                    })
                .withOption('ajax', function (data, callback, settings) {
                    var pageNum = (data.start / data.length) + 1;
                    var totalNumberOfNodes = 0;
                    PeerService.getStats().then(function (success) {
                        totalNumberOfNodes = success.totalNodes;
                        PeerService.getPeers(pageNum, data.length).then(function (response) {
                            var data = {'peers': response};
                            callback({
                                'iTotalRecords': totalNumberOfNodes,
                                'iTotalDisplayRecords': totalNumberOfNodes,
                                'peers': data.peers
                            });
                        });
                    }, function (error) {

                    });
                })
                .withDisplayLength(10).withBootstrap()
                .withOption('rank', [1, 'desc'])
                .withOption('rowReordering', true);

            $scope.dtColumns = [
                DTColumnBuilder.newColumn('connected').withTitle('Connected').notSortable()
                    .renderWith(function (data, type, row, meta) {
                        return getConnectedUiModel(row.active) + '<br/><span tooltip-placement="right" uib-tooltip="Last connected successfully" style="color:black"><small>' + formatDate(row.lastConnected, true) + '</small></span>';
                    }),

                DTColumnBuilder.newColumn('state').withTitle('State').notSortable()
                    .renderWith(function (data, type, row, meta) {
                            if (!row.peerState) {
                                return "n/a";
                            }

                            if (row.peerState.isDownloading) {
                                return iconToolTip('<div class="iep-icon-download"></div>', "Node is syncing (not ready)");
                            } else if (row.peerState.isScanning) {
                                return iconToolTip('<div class="iep-icon-scan"></div>', "Node is searching nodes (not ready)");
                            } else {
                                return iconToolTip('<div class="iep-icon-running"></div>', "Node is in sync (ready)");
                            }
                        }
                    ),

                DTColumnBuilder.newColumn('rank').withTitle('Rank').notSortable()
                    .renderWith(function (data, type, row, meta) {
                        if (!row.peerState) {
                            return "n/a";
                        }
                        return (row.peerState.rank).toFixed(2);
                    }),

                DTColumnBuilder.newColumn('_id').withTitle('IP').notSortable()
                  .renderWith(function (data, type, row, meta) {
                      if (!row.peerState) {
                          return data;
                      }

                    return '<a type="button" class="btn btn-infinity btn-xs" style="min-width:100%;" ng-controller="SearchCtrl" ng-click="searchIP(\'' +
                    row._id + '\' )"></strong>' + data + '</strong></a>';
                  }),

                DTColumnBuilder.newColumn('numberOfActivePeers').withTitle('Active Peers').notSortable()
                    .renderWith(function (data, type, row, meta) {
                        if (!row.peerState) {
                            return "n/a";
                        }

                        return '<span tooltip-placement="right" uib-tooltip="Total peers: ' + row.peerState.numberOfPeers + '" style="color:black">' + row.peerState.numberOfActivePeers + '</span>';
                    }),

                DTColumnBuilder.newColumn('SystemLoadAverage').withTitle('CPU').notSortable()
                    .renderWith(function (data, type, row, meta) {

                        if (!row.peerState) {
                            return "n/a";
                        }

                        var numCPU = parseInt(row.peerState.availableProcessors);
                        var loadAvg = parseFloat(row.peerState.SystemLoadAverage);
                        var loadPct   = (loadAvg * 100 /  (numCPU * 100) ) * 100;

                        return (loadPct.toFixed(2) + ' %');

                    }),

                DTColumnBuilder.newColumn('history_SystemLoadAverage').withTitle('CPU Load History').notSortable()
                    .renderWith(function (data, type, row, meta) {

                        if (!row.peerState) {
                            return "n/a";
                        }

                        var tmpArr = [];

                        var field = row.peerState.history_SystemLoadAverage;

                        for (var i = 0; i < field.length - 30; i++) {

                            var loadAvg = parseFloat(field[i]);
                            var loadPct = (loadAvg * 100 / (1 * 100) ) * 100;

                            tmpArr.push({label: i, value: loadPct});

                        }

                        var dd = [{values: []}];
                        dd[0].values = tmpArr;

                        return '<nvd3 options="chartOptions" data=' + JSON.stringify(dd) + '></nvd3>';

                    }),

                DTColumnBuilder.newColumn('lastBlockchainFeeder').withTitle('Last Feeder').notSortable()
                .renderWith(function (data, type, row, meta) {
                    if (!row.peerState) {
                        return "n/a";
                    }

                  return '<a class="pointer" ng-controller="SearchCtrl" ng-click="searchIP(\'' +
                  row.peerState.lastBlockchainFeeder + '\' )">' + row.peerState.lastBlockchainFeeder + '</a>';
                }),

                DTColumnBuilder.newColumn('numberOfBlocks').withTitle('Height').notSortable()
                    .renderWith(function (data, type, row, meta) {
                        if (!row.peerState) {
                            return "n/a";
                        }

                        return row.peerState.lastBlockchainFeederHeight;
                    }),

                DTColumnBuilder.newColumn('version').withTitle('Version').notSortable()
                    .renderWith(function (data, type, row, meta) {
                        return row.version;
                    }),

                DTColumnBuilder.newColumn('enableHallmarkProtection').withTitle('Marked').notSortable()
                    .renderWith(function (data, type, row, meta) {

                        const tick = row.peerState && row.peerState.enableHallmarkProtection
                        return getTickMarkUiModel(tick, 'Hallmark Protected');
                    }),

                DTColumnBuilder.newColumn('apiServerEnable').withTitle('API').notSortable()
                    .renderWith(function (data, type, row, meta) {

                        return getTickMarkUiModel(row.services.includes("API"), 'API Enabled on Port ' + row.apiPort);
                    }),

                DTColumnBuilder.newColumn('geoip').withTitle('Country').notSortable()
                    .renderWith(function (data, type, row, meta) {
                        if (row.geoip) {
                            var toolTipText = row.geoip.countryName;
                            return '<countryflag country="' + row.geoip.country.toLowerCase() + '" isSquare="false" uib-tooltip="' + toolTipText + '"></countryflag>';
                        }
                        return "n/a";
                    }),


            ];

            function getTickMarkUiModel(value, toolTipText) {
                if (value === true) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTipText +
                        '" class="iep-icon-checkbox-checked " style="color:black"></span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTipText +
                        '" class="iep-icon-checkbox-unchecked " style="color:black"></span>';
                }
            }

            function getConnectedUiModel(value) {
                if (value) {
                    return '<span tooltip-placement="right" uib-tooltip="Peer is connected" class="iep-icon-checkbox-checked " style="color:black"></span>';
                } else {
                    return '<span tooltip-placement="right" uib-tooltip="Peer is not connected, trying to reconnect or removing peer" style="color:black"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></span>';
                }
            }

            function formatDate(d, withTime) {
                if (!d || typeof d === "undefined") {
                    return "n/a";
                }
                if (typeof withTime === "undefined") {
                    withTime = false;
                }

                var browserLocale = navigator.language || navigator.userLanguage || "en-US";

                var date = new Date(d);

                return date.toLocaleDateString(browserLocale) + (withTime ? " " + date.toLocaleTimeString(browserLocale) : "");
            }

            function iconToolTip(icon, toolTipText) {
                return'<span tooltip-placement="top" uib-tooltip="' + toolTipText +
                    '">'+icon+'</span>';
            }

            $scope.dtInstanceCallback= function (_dtInstance) {
                $scope.dtInstance = _dtInstance;
            };
            $scope.reloadPeers = function () {
                if ($scope.dtInstance) {
                    $scope.dtInstance._renderer.rerender();
                }
            };


        }]);

angular.module('peers').controller('StatsCtrl',
    ['$scope', 'PeerService', 'DTOptionsBuilder', 'DTColumnBuilder', '$interval', '$uibModal', '$compile',
        function ($scope, PeerService, DTOptionsBuilder, DTColumnBuilder, $interval, $uibModal, $compile) {

            $scope.getStats = function () {
                PeerService.getStats().then(function (success) {

                    $scope.stats = success;

                }, function (error) {

                });
            };
        }]);

/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/

angular.module('search',
    ['restangular', 'datatables', 'datatables.bootstrap', 'ui.bootstrap', 'ui.router','nvd3','ngSanitize']);

angular.module('search').constant('searchConfig', {
  'searchEndPoint': 'api/nodes',
});

angular.module('search').config(['RestangularProvider', 'searchConfig', '$stateProvider', '$urlRouterProvider', 'peerConfig',
    function (RestangularProvider, searchConfig, $stateProvider, $urlRouterProvider, peerConfig) {
        RestangularProvider.setBaseUrl(peerConfig.apiUrl);

        $stateProvider.state('peerExplorer.search', {
            url: '^/search',
            templateUrl: './search/search.html',
            controller: 'SearchCtrl'

        });
    }]);

angular.module('search').filter('html', ['$sce', function ($sce) {
    return function (val) {
        return $sce.trustAsHtml(val);
    };
}]);

/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/

angular.module('search').service('SearchService', ['Restangular', 'searchConfig', function (Restangular, searchConfig) {

    this.searchIp = function (ip) {
        var params = {
            'ip': ip
        };
        return Restangular.all(searchConfig.searchEndPoint).customGET('', params);
    };

}]);

/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/

angular.module('search').controller('SearchCtrl',
    ['$scope', 'SearchService', 'DTOptionsBuilder', 'DTColumnBuilder', '$interval', '$uibModal', '$compile',
        'searchConfig', 'peerConfig',
        function ($scope, SearchService, DTOptionsBuilder, DTColumnBuilder, $interval, $uibModal, $compile,
                  searchConfig, peerConfig) {

            $scope.showSearchBar = false;

            var errorHandler = function (errorMessage) {
                $uibModal.open({
                    animation: true,
                    templateUrl: 'search/search-error.html',
                    size: 'sm',
                    controller: 'ErrorSearchCtrl',

                    resolve: {
                        params: function () {
                            return {
                                message: errorMessage
                            };
                        }
                    }
                });
            };

            function validateIPaddress(ipaddress) {
                if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                        ipaddress)) {
                    return true;
                }
                return (false);
            }

            $scope.search = function (searchTerm) {
                searchTerm = searchTerm||$scope.searchTerm;
                if (searchTerm) {
                    if (validateIPaddress(searchTerm)) {
                        SearchService.searchIp(searchTerm).then(function (response) {
                            if (response._id) {
                                $uibModal.open({
                                    animation: true,
                                    templateUrl: 'search/search-peer.html',
                                    size: 'lg',
                                    controller: 'SearchIpCtrl',
                                    windowClass: 'block-modal-window',
                                    resolve: {
                                        params: function () {
                                            return {
                                                node: response
                                            };
                                        }
                                    }
                                });
                            } else {
                                errorHandler(searchTerm + ' ip doesn\'t exists ');
                            }
                        });
                    } else {
                        errorHandler('Please enter valid ip address');
                    }
                }

            };

            $scope.searchIP = function (searchTerm) {

                if (searchTerm) {
                    if (validateIPaddress(searchTerm)) {
                        SearchService.searchIp(searchTerm).then(function (response) {
                                if (response._id) {
                                    $uibModal.open({
                                        animation: true,
                                        templateUrl: 'search/search-peer.html',
                                        size: 'lg',
                                        controller: 'SearchIpCtrl',
                                        windowClass: 'block-modal-window',
                                        resolve: {
                                            params: function () {
                                                return {
                                                    node: response
                                                };
                                            }
                                        }
                                    });
                                } else {
                                    errorHandler(searchTerm + ' ip doesn\'t exists ');
                                }
                            }
                        );
                    } else {
                        errorHandler('Please enter valid ip address');
                    }
                }

            };

        }]);

angular.module('search')
    .controller('SearchIpCtrl',
        ['$scope', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$uibModalInstance', '$q', 'params',
            function ($scope, DTOptionsBuilder, DTColumnBuilder, $compile, $uibModalInstance, $q, params) {

              $scope.chartOptions = {
                  chart: {
                      type: 'discreteBarChart',
                      height: 100,
                      margin : {
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0
                      },
                      x: function(d){return d.label;},
                      y: function(d){return d.value;},
                      showValues: false,
                      valueFormat: function(d){
                          return d3.format(',.2f')(d);
                      },
                      duration: 500,
                      xAxis: {
                          axisLabel: '',
                          ticks: 8
                      },
                      yAxis: {
                          axisLabel: '',
                          axisLabelDistance: 0,
                          ticks: 8
                      },

                      color: function(){
                        return '#9e9e9e';
                      },


                  },

              };

              function buildChartDataArray(data){
                var obj = {
                    key: 'SystemLoad',
                    values: []
                };

                for (var i = 0; i < data.length; i++) {
                  obj.values.push( { label: i, value: data[i] } );
                }
                return [obj];
              }

              function buildChartSystemLoadAverage(data){
                var obj = {
                    key: 'SystemLoad',
                    values: []
                };

                for (var i = 0; i < data.length; i++) {

                  var loadAvg = parseFloat( data[i] );
                  var loadPct  = (loadAvg * 100 /  (1 * 100) ) * 100;
                  obj.values.push( { label: i, value: loadPct } );

                }

                return [obj];

              }

                $scope.cancel = function () {
                    $uibModalInstance.close();
                };

                $scope.showResult = function () {

                    $scope.node = params.node;

                    $scope.chartOptions = $scope.chartOptions;
                    $scope.chartData1 =  buildChartSystemLoadAverage(  params.node.peerState.history_SystemLoadAverage );
                    $scope.chartData2 =  buildChartDataArray(  params.node.peerState.history_freeMemory );
                    $scope.chartData3 =  buildChartDataArray(  params.node.peerState.history_requestProcessingTime );
                    $scope.chartData4 =  buildChartDataArray(  params.node.peerState.history_numberOfActivePeers );
                };


            }]);

angular.module('search').controller('ErrorSearchCtrl', ['$scope', '$uibModalInstance', '$q', 'params',
    function ($scope, $uibModalInstance, $q, params) {
        $scope.cancel = function () {
            $uibModalInstance.close('cancel');
        };

        $scope.showResult = function () {
            $scope.message = params.message;
        };
    }]);

/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/

angular.module('peerExplorer', ['peers', 'search', 'ui.router']);

angular.module('peerExplorer')
    .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
        angular.lowercase = function (text) {
            return text.toLowerCase(text);
        };

        $stateProvider.state('peerExplorer', {
            abstract: true,
            url: '/',
            template: '<div ui-view></div>',
        });

        $urlRouterProvider.otherwise('/peers');

        document.title = "CPCoin - New Peer Explorer | " + window.getEnvConfig("NETWORK_ENVIRONMENT");
    }]);

angular.module('peerExplorer').filter('isEnabled', ['$sce', function ($sce) {
    return function (val) {
        switch (val) {
            case true:
                return '<span class="iep-icon-checkbox-checked" style="color:black"></span>';
            case false:
                return '<span class="iep-icon-checkbox-unchecked" style="color:black"></span>';
            default:
                return '<span class="iep-icon-checkbox-unchecked" style="color:black"></span>';
        }
    };
}]);

angular.module('peerExplorer').filter('isEnabledSmall', ['$sce', function ($sce) {
    return function (val) {
        switch (val) {
            case true:
                return '<span class="iep-icon-checkbox-checked iep-icon-small" style="color:black"></span>';
            case false:
                return '<span class="iep-icon-checkbox-unchecked iep-icon-small" style="color:black"></span>';
            default:
                return '<span class="iep-icon-checkbox-unchecked iep-icon-small" style="color:black"></span>';
        }
    };
}]);

angular.module('peerExplorer').run(['$rootScope', 'PEER_CONSTANTS', function ($rootScope, PEER_CONSTANTS) {
    $rootScope.options = PEER_CONSTANTS;
}]);

angular.module('peerExplorer').filter('gateways', ['$sce', function ($sce) {
    return function (toolTip, val) {
        switch (toolTip) {
            case 'TenderMint':

                if (val) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">   <span class="label label-default" >TM</span>  </span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >TM</span>   </span>';
                }
                break;

            case 'ZeroNet':

                if (val) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >ZN</span>  </span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >ZN</span>  </span>';
                }
                break;
            case 'IPFS':

                if (val) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-warning" >IPFS</span>  </span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >IPFS</span>  </span>';
                }
                break;

            default:
                return '<small> <span class="glyphicon glyphicon-remove" style="color:red"></span> </small>';
        }
    };
}]);

angular.module('peerExplorer').filter('proxies', ['$sce', function ($sce) {
    return function (toolTip, val) {

        switch (toolTip) {
            case 'BTC':

                if (val) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">   <span class="label label-warning" >BTC</span>  </span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >BTC</span>   </span>';
                }
                break;

            case 'ETH':

                if (val) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >ETH</span>  </span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >ETH</span>  </span>';
                }
                break;
            case 'LTC':

                if (val) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >LTC</span>  </span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >LTC</span>  </span>';
                }
                break;

            case 'XRP':

                if (val) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >XRP</span>  </span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >XRP</span>  </span>';
                }
                break;

            case 'MKT':

                if (val) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >MKT</span>  </span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >MKT</span>  </span>';
                }
                break;

            default:
                return '<small> <span class="glyphicon glyphicon-remove" style="color:red"></span> </small>';
        }


    };
}]);

angular.module('peerExplorer').filter('storage', ['$sce', function ($sce) {
    return function (toolTip, val) {

        switch (toolTip) {
            case 'PostgreSQL':

                if (val) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">   <span class="label label-default" >PS</span>  </span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >PS</span>   </span>';
                }
                break;

            case 'RethinkDB':

                if (val) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >RT</span>  </span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >RT</span>  </span>';
                }
                break;
            case 'MySQL':

                if (val) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >MY</span>  </span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >MY</span>  </span>';
                }
                break;

            case 'Mongodb':

                if (val) {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-warning" >MO</span>  </span>';
                } else {
                    return '<span tooltip-placement="top" uib-tooltip="' + toolTip + '">  <span class="label label-default" >MO</span>  </span>';
                }
                break;


            default:
                return '<small> <span class="glyphicon glyphicon-remove" style="color:red"></span> </small>';
        }


    };
}]);

angular.module('peerExplorer').filter('numericalString', ['$sce',  function ($sce) {
    return function (val) {
        if (!val) {
            val = 0;
        }
        return val.toLocaleString('en-US', {minimumFractionDigits: 2});
    };
}]);

angular.module('peerExplorer').filter('bytesToMB', ['$sce',  function ($sce) {
    return function (val) {
        if (!val) {
            val = 0;
        } else {
            val = Math.floor(val / 1024 / 1024);
        }
        return val + " MB";
    };
}]);

/******************************************************************************
 * Copyright © 2017 XIN Community                                             *
 *                                                                            *
 * See the DEVELOPER-AGREEMENT.txt and LICENSE.txt files at  the top-level    *
 * directory of this distribution for the individual copyright  holder        *
 * information and the developer policies on copyright and licensing.         *
 *                                                                            *
 * Unless otherwise agreed in a custom licensing agreement, no part of the    *
 * XIN software, including this file, may be copied, modified, propagated,    *
 * or distributed except according to the terms contained in the LICENSE.txt  *
 * file.                                                                      *
 *                                                                            *
 * Removal or modification of this copyright notice is prohibited.            *
 *                                                                            *
 ******************************************************************************/

angular.module('peerExplorer').controller('FooterController', ['PeerService', '$scope', '$rootScope','peerConfig',
    function (PeerService, $scope, $rootScope,peerConfig) {

        $scope.init = function () {
            PeerService.getPeers(1,2).then(function (success) {

                $scope.topNode=success[0]||{};
            });
            $scope.connectedURL=peerConfig.apiUrl;
        };

    }]);
